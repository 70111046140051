.text {
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.text > div {
    display: inline-block;
    position: relative;
    font-size: 10vmin;
    color: transparent;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: float 4s infinite;
    animation: float 4s infinite;
}
.text > div:nth-child(1) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}
.text > div:nth-child(2) {
    -webkit-animation-delay: -1.8s;
    animation-delay: -1.8s;
}
.text > div:nth-child(3) {
    -webkit-animation-delay: -1.6s;
    animation-delay: -1.6s;
}
.text > div:nth-child(4) {
    -webkit-animation-delay: -1.4s;
    animation-delay: -1.4s;
}
.text > div:nth-child(5) {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}
.text > div:nth-child(6) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.text > div:nth-child(7) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.text > div:nth-child(8) {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.text > div > div {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    text-shadow: 0 0 1px white;
}
.text > div > div:not(:nth-child(n+3)), body .text > div > div:not(:nth-last-child(n+3)) {
    color: #ed5565;
    text-shadow: 0 0 1px #ed5565;
}
.text > div > div:nth-child(1) {
    -webkit-transform: translateZ(-3.84vmin);
    transform: translateZ(-3.84vmin);
}
.text > div > div:nth-child(2) {
    -webkit-transform: translateZ(-2.88vmin);
    transform: translateZ(-2.88vmin);
}
.text > div > div:nth-child(3) {
    -webkit-transform: translateZ(-1.92vmin);
    transform: translateZ(-1.92vmin);
}
.text > div > div:nth-child(4) {
    -webkit-transform: translateZ(-0.96vmin);
    transform: translateZ(-0.96vmin);
}
.text > div > div:nth-child(5) {
    -webkit-transform: translateZ(0vmin);
    transform: translateZ(0vmin);
}
.text > div > div:nth-child(6) {
    -webkit-transform: translateZ(0.96vmin);
    transform: translateZ(0.96vmin);
}
.text > div > div:nth-child(7) {
    -webkit-transform: translateZ(1.92vmin);
    transform: translateZ(1.92vmin);
}
.text > div > div:nth-child(8) {
    -webkit-transform: translateZ(2.88vmin);
    transform: translateZ(2.88vmin);
}
.text > div > div:nth-child(9) {
    -webkit-transform: translateZ(3.84vmin);
    transform: translateZ(3.84vmin);
}
.text > div > div:nth-child(10) {
    -webkit-transform: translateZ(4.8vmin);
    transform: translateZ(4.8vmin);
}

@-webkit-keyframes float {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 15deg);
        transform: rotate3d(0, 1, 0, 15deg);
    }
    1% {
        -webkit-transform: rotate3d(0.0627905195, 0.9980267284, 0, 15deg);
        transform: rotate3d(0.0627905195, 0.9980267284, 0, 15deg);
    }
    2% {
        -webkit-transform: rotate3d(0.1253332336, 0.9921147013, 0, 15deg);
        transform: rotate3d(0.1253332336, 0.9921147013, 0, 15deg);
    }
    3% {
        -webkit-transform: rotate3d(0.1873813146, 0.9822872507, 0, 15deg);
        transform: rotate3d(0.1873813146, 0.9822872507, 0, 15deg);
    }
    4% {
        -webkit-transform: rotate3d(0.2486898872, 0.9685831611, 0, 15deg);
        transform: rotate3d(0.2486898872, 0.9685831611, 0, 15deg);
    }
    5% {
        -webkit-transform: rotate3d(0.3090169944, 0.9510565163, 0, 15deg);
        transform: rotate3d(0.3090169944, 0.9510565163, 0, 15deg);
    }
    6% {
        -webkit-transform: rotate3d(0.3681245527, 0.9297764859, 0, 15deg);
        transform: rotate3d(0.3681245527, 0.9297764859, 0, 15deg);
    }
    7% {
        -webkit-transform: rotate3d(0.4257792916, 0.9048270525, 0, 15deg);
        transform: rotate3d(0.4257792916, 0.9048270525, 0, 15deg);
    }
    8% {
        -webkit-transform: rotate3d(0.4817536741, 0.87630668, 0, 15deg);
        transform: rotate3d(0.4817536741, 0.87630668, 0, 15deg);
    }
    9% {
        -webkit-transform: rotate3d(0.535826795, 0.8443279255, 0, 15deg);
        transform: rotate3d(0.535826795, 0.8443279255, 0, 15deg);
    }
    10% {
        -webkit-transform: rotate3d(0.5877852523, 0.8090169944, 0, 15deg);
        transform: rotate3d(0.5877852523, 0.8090169944, 0, 15deg);
    }
    11% {
        -webkit-transform: rotate3d(0.6374239897, 0.7705132428, 0, 15deg);
        transform: rotate3d(0.6374239897, 0.7705132428, 0, 15deg);
    }
    12% {
        -webkit-transform: rotate3d(0.6845471059, 0.7289686274, 0, 15deg);
        transform: rotate3d(0.6845471059, 0.7289686274, 0, 15deg);
    }
    13% {
        -webkit-transform: rotate3d(0.7289686274, 0.6845471059, 0, 15deg);
        transform: rotate3d(0.7289686274, 0.6845471059, 0, 15deg);
    }
    14% {
        -webkit-transform: rotate3d(0.7705132428, 0.6374239897, 0, 15deg);
        transform: rotate3d(0.7705132428, 0.6374239897, 0, 15deg);
    }
    15% {
        -webkit-transform: rotate3d(0.8090169944, 0.5877852523, 0, 15deg);
        transform: rotate3d(0.8090169944, 0.5877852523, 0, 15deg);
    }
    16% {
        -webkit-transform: rotate3d(0.8443279255, 0.535826795, 0, 15deg);
        transform: rotate3d(0.8443279255, 0.535826795, 0, 15deg);
    }
    17% {
        -webkit-transform: rotate3d(0.87630668, 0.4817536741, 0, 15deg);
        transform: rotate3d(0.87630668, 0.4817536741, 0, 15deg);
    }
    18% {
        -webkit-transform: rotate3d(0.9048270525, 0.4257792916, 0, 15deg);
        transform: rotate3d(0.9048270525, 0.4257792916, 0, 15deg);
    }
    19% {
        -webkit-transform: rotate3d(0.9297764859, 0.3681245527, 0, 15deg);
        transform: rotate3d(0.9297764859, 0.3681245527, 0, 15deg);
    }
    20% {
        -webkit-transform: rotate3d(0.9510565163, 0.3090169944, 0, 15deg);
        transform: rotate3d(0.9510565163, 0.3090169944, 0, 15deg);
    }
    21% {
        -webkit-transform: rotate3d(0.9685831611, 0.2486898872, 0, 15deg);
        transform: rotate3d(0.9685831611, 0.2486898872, 0, 15deg);
    }
    22% {
        -webkit-transform: rotate3d(0.9822872507, 0.1873813146, 0, 15deg);
        transform: rotate3d(0.9822872507, 0.1873813146, 0, 15deg);
    }
    23% {
        -webkit-transform: rotate3d(0.9921147013, 0.1253332336, 0, 15deg);
        transform: rotate3d(0.9921147013, 0.1253332336, 0, 15deg);
    }
    24% {
        -webkit-transform: rotate3d(0.9980267284, 0.0627905195, 0, 15deg);
        transform: rotate3d(0.9980267284, 0.0627905195, 0, 15deg);
    }
    25% {
        -webkit-transform: rotate3d(1, 0, 0, 15deg);
        transform: rotate3d(1, 0, 0, 15deg);
    }
    26% {
        -webkit-transform: rotate3d(0.9980267284, -0.0627905195, 0, 15deg);
        transform: rotate3d(0.9980267284, -0.0627905195, 0, 15deg);
    }
    27% {
        -webkit-transform: rotate3d(0.9921147013, -0.1253332336, 0, 15deg);
        transform: rotate3d(0.9921147013, -0.1253332336, 0, 15deg);
    }
    28% {
        -webkit-transform: rotate3d(0.9822872507, -0.1873813146, 0, 15deg);
        transform: rotate3d(0.9822872507, -0.1873813146, 0, 15deg);
    }
    29% {
        -webkit-transform: rotate3d(0.9685831611, -0.2486898872, 0, 15deg);
        transform: rotate3d(0.9685831611, -0.2486898872, 0, 15deg);
    }
    30% {
        -webkit-transform: rotate3d(0.9510565163, -0.3090169944, 0, 15deg);
        transform: rotate3d(0.9510565163, -0.3090169944, 0, 15deg);
    }
    31% {
        -webkit-transform: rotate3d(0.9297764859, -0.3681245527, 0, 15deg);
        transform: rotate3d(0.9297764859, -0.3681245527, 0, 15deg);
    }
    32% {
        -webkit-transform: rotate3d(0.9048270525, -0.4257792916, 0, 15deg);
        transform: rotate3d(0.9048270525, -0.4257792916, 0, 15deg);
    }
    33% {
        -webkit-transform: rotate3d(0.87630668, -0.4817536741, 0, 15deg);
        transform: rotate3d(0.87630668, -0.4817536741, 0, 15deg);
    }
    34% {
        -webkit-transform: rotate3d(0.8443279255, -0.535826795, 0, 15deg);
        transform: rotate3d(0.8443279255, -0.535826795, 0, 15deg);
    }
    35% {
        -webkit-transform: rotate3d(0.8090169944, -0.5877852523, 0, 15deg);
        transform: rotate3d(0.8090169944, -0.5877852523, 0, 15deg);
    }
    36% {
        -webkit-transform: rotate3d(0.7705132428, -0.6374239897, 0, 15deg);
        transform: rotate3d(0.7705132428, -0.6374239897, 0, 15deg);
    }
    37% {
        -webkit-transform: rotate3d(0.7289686274, -0.6845471059, 0, 15deg);
        transform: rotate3d(0.7289686274, -0.6845471059, 0, 15deg);
    }
    38% {
        -webkit-transform: rotate3d(0.6845471059, -0.7289686274, 0, 15deg);
        transform: rotate3d(0.6845471059, -0.7289686274, 0, 15deg);
    }
    39% {
        -webkit-transform: rotate3d(0.6374239897, -0.7705132428, 0, 15deg);
        transform: rotate3d(0.6374239897, -0.7705132428, 0, 15deg);
    }
    40% {
        -webkit-transform: rotate3d(0.5877852523, -0.8090169944, 0, 15deg);
        transform: rotate3d(0.5877852523, -0.8090169944, 0, 15deg);
    }
    41% {
        -webkit-transform: rotate3d(0.535826795, -0.8443279255, 0, 15deg);
        transform: rotate3d(0.535826795, -0.8443279255, 0, 15deg);
    }
    42% {
        -webkit-transform: rotate3d(0.4817536741, -0.87630668, 0, 15deg);
        transform: rotate3d(0.4817536741, -0.87630668, 0, 15deg);
    }
    43% {
        -webkit-transform: rotate3d(0.4257792916, -0.9048270525, 0, 15deg);
        transform: rotate3d(0.4257792916, -0.9048270525, 0, 15deg);
    }
    44% {
        -webkit-transform: rotate3d(0.3681245527, -0.9297764859, 0, 15deg);
        transform: rotate3d(0.3681245527, -0.9297764859, 0, 15deg);
    }
    45% {
        -webkit-transform: rotate3d(0.3090169944, -0.9510565163, 0, 15deg);
        transform: rotate3d(0.3090169944, -0.9510565163, 0, 15deg);
    }
    46% {
        -webkit-transform: rotate3d(0.2486898872, -0.9685831611, 0, 15deg);
        transform: rotate3d(0.2486898872, -0.9685831611, 0, 15deg);
    }
    47% {
        -webkit-transform: rotate3d(0.1873813146, -0.9822872507, 0, 15deg);
        transform: rotate3d(0.1873813146, -0.9822872507, 0, 15deg);
    }
    48% {
        -webkit-transform: rotate3d(0.1253332336, -0.9921147013, 0, 15deg);
        transform: rotate3d(0.1253332336, -0.9921147013, 0, 15deg);
    }
    49% {
        -webkit-transform: rotate3d(0.0627905195, -0.9980267284, 0, 15deg);
        transform: rotate3d(0.0627905195, -0.9980267284, 0, 15deg);
    }
    50% {
        -webkit-transform: rotate3d(0, -0.9999999999, 0, 15deg);
        transform: rotate3d(0, -0.9999999999, 0, 15deg);
    }
    51% {
        -webkit-transform: rotate3d(-0.0627905195, -0.9980267283, 0, 15deg);
        transform: rotate3d(-0.0627905195, -0.9980267283, 0, 15deg);
    }
    52% {
        -webkit-transform: rotate3d(-0.1253332335, -0.9921147011, 0, 15deg);
        transform: rotate3d(-0.1253332335, -0.9921147011, 0, 15deg);
    }
    53% {
        -webkit-transform: rotate3d(-0.1873813145, -0.9822872505, 0, 15deg);
        transform: rotate3d(-0.1873813145, -0.9822872505, 0, 15deg);
    }
    54% {
        -webkit-transform: rotate3d(-0.2486898871, -0.9685831607, 0, 15deg);
        transform: rotate3d(-0.2486898871, -0.9685831607, 0, 15deg);
    }
    55% {
        -webkit-transform: rotate3d(-0.3090169943, -0.9510565157, 0, 15deg);
        transform: rotate3d(-0.3090169943, -0.9510565157, 0, 15deg);
    }
    56% {
        -webkit-transform: rotate3d(-0.3681245525, -0.929776485, 0, 15deg);
        transform: rotate3d(-0.3681245525, -0.929776485, 0, 15deg);
    }
    57% {
        -webkit-transform: rotate3d(-0.4257792914, -0.9048270511, 0, 15deg);
        transform: rotate3d(-0.4257792914, -0.9048270511, 0, 15deg);
    }
    58% {
        -webkit-transform: rotate3d(-0.4817536738, -0.8763066781, 0, 15deg);
        transform: rotate3d(-0.4817536738, -0.8763066781, 0, 15deg);
    }
    59% {
        -webkit-transform: rotate3d(-0.5358267945, -0.8443279226, 0, 15deg);
        transform: rotate3d(-0.5358267945, -0.8443279226, 0, 15deg);
    }
    60% {
        -webkit-transform: rotate3d(-0.5877852516, -0.8090169902, 0, 15deg);
        transform: rotate3d(-0.5877852516, -0.8090169902, 0, 15deg);
    }
    61% {
        -webkit-transform: rotate3d(-0.6374239888, -0.7705132368, 0, 15deg);
        transform: rotate3d(-0.6374239888, -0.7705132368, 0, 15deg);
    }
    62% {
        -webkit-transform: rotate3d(-0.6845471045, -0.7289686189, 0, 15deg);
        transform: rotate3d(-0.6845471045, -0.7289686189, 0, 15deg);
    }
    63% {
        -webkit-transform: rotate3d(-0.7289686253, -0.6845470938, 0, 15deg);
        transform: rotate3d(-0.7289686253, -0.6845470938, 0, 15deg);
    }
    64% {
        -webkit-transform: rotate3d(-0.7705132398, -0.6374239727, 0, 15deg);
        transform: rotate3d(-0.7705132398, -0.6374239727, 0, 15deg);
    }
    65% {
        -webkit-transform: rotate3d(-0.8090169901, -0.5877852283, 0, 15deg);
        transform: rotate3d(-0.8090169901, -0.5877852283, 0, 15deg);
    }
    66% {
        -webkit-transform: rotate3d(-0.8443279194, -0.5358267614, 0, 15deg);
        transform: rotate3d(-0.8443279194, -0.5358267614, 0, 15deg);
    }
    67% {
        -webkit-transform: rotate3d(-0.8763066715, -0.4817536274, 0, 15deg);
        transform: rotate3d(-0.8763066715, -0.4817536274, 0, 15deg);
    }
    68% {
        -webkit-transform: rotate3d(-0.9048270404, -0.425779227, 0, 15deg);
        transform: rotate3d(-0.9048270404, -0.425779227, 0, 15deg);
    }
    69% {
        -webkit-transform: rotate3d(-0.9297764691, -0.3681244637, 0, 15deg);
        transform: rotate3d(-0.9297764691, -0.3681244637, 0, 15deg);
    }
    70% {
        -webkit-transform: rotate3d(-0.9510564929, -0.3090168724, 0, 15deg);
        transform: rotate3d(-0.9510564929, -0.3090168724, 0, 15deg);
    }
    71% {
        -webkit-transform: rotate3d(-0.9685831287, -0.2486897207, 0, 15deg);
        transform: rotate3d(-0.9685831287, -0.2486897207, 0, 15deg);
    }
    72% {
        -webkit-transform: rotate3d(-0.9822872061, -0.1873810883, 0, 15deg);
        transform: rotate3d(-0.9822872061, -0.1873810883, 0, 15deg);
    }
    73% {
        -webkit-transform: rotate3d(-0.9921146401, -0.1253329274, 0, 15deg);
        transform: rotate3d(-0.9921146401, -0.1253329274, 0, 15deg);
    }
    74% {
        -webkit-transform: rotate3d(-0.9980266448, -0.0627901069, 0, 15deg);
        transform: rotate3d(-0.9980266448, -0.0627901069, 0, 15deg);
    }
    75% {
        -webkit-transform: rotate3d(-0.9999998862, 0.0000005537, 0, 15deg);
        transform: rotate3d(-0.9999998862, 0.0000005537, 0, 15deg);
    }
    76% {
        -webkit-transform: rotate3d(-0.9980265742, 0.0627912598, 0, 15deg);
        transform: rotate3d(-0.9980265742, 0.0627912598, 0, 15deg);
    }
    77% {
        -webkit-transform: rotate3d(-0.9921144932, 0.1253342195, 0, 15deg);
        transform: rotate3d(-0.9921144932, 0.1253342195, 0, 15deg);
    }
    78% {
        -webkit-transform: rotate3d(-0.982286971, 0.1873826227, 0, 15deg);
        transform: rotate3d(-0.982286971, 0.1873826227, 0, 15deg);
    }
    79% {
        -webkit-transform: rotate3d(-0.9685827866, 0.2486916166, 0, 15deg);
        transform: rotate3d(-0.9685827866, 0.2486916166, 0, 15deg);
    }
    80% {
        -webkit-transform: rotate3d(-0.9510560166, 0.3090192726, 0, 15deg);
        transform: rotate3d(-0.9510560166, 0.3090192726, 0, 15deg);
    }
    81% {
        -webkit-transform: rotate3d(-0.9297758216, 0.3681275437, 0, 15deg);
        transform: rotate3d(-0.9297758216, 0.3681275437, 0, 15deg);
    }
    82% {
        -webkit-transform: rotate3d(-0.9048261725, 0.425783205, 0, 15deg);
        transform: rotate3d(-0.9048261725, 0.425783205, 0, 15deg);
    }
    83% {
        -webkit-transform: rotate3d(-0.8763055184, 0.4817587777, 0, 15deg);
        transform: rotate3d(-0.8763055184, 0.4817587777, 0, 15deg);
    }
    84% {
        -webkit-transform: rotate3d(-0.8443263971, 0.5358334293, 0, 15deg);
        transform: rotate3d(-0.8443263971, 0.5358334293, 0, 15deg);
    }
    85% {
        -webkit-transform: rotate3d(-0.80901499, 0.5877938496, 0, 15deg);
        transform: rotate3d(-0.80901499, 0.5877938496, 0, 15deg);
    }
    86% {
        -webkit-transform: rotate3d(-0.7705106226, 0.6374350969, 0, 15deg);
        transform: rotate3d(-0.7705106226, 0.6374350969, 0, 15deg);
    }
    87% {
        -webkit-transform: rotate3d(-0.7289652129, 0.6845614127, 0, 15deg);
        transform: rotate3d(-0.7289652129, 0.6845614127, 0, 15deg);
    }
    88% {
        -webkit-transform: rotate3d(-0.6845426698, 0.7289870018, 0, 15deg);
        transform: rotate3d(-0.6845426698, 0.7289870018, 0, 15deg);
    }
    89% {
        -webkit-transform: rotate3d(-0.6374182434, 0.7705367741, 0, 15deg);
        transform: rotate3d(-0.6374182434, 0.7705367741, 0, 15deg);
    }
    90% {
        -webkit-transform: rotate3d(-0.5877778306, 0.8090470459, 0, 15deg);
        transform: rotate3d(-0.5877778306, 0.8090470459, 0, 15deg);
    }
    91% {
        -webkit-transform: rotate3d(-0.5358172367, 0.8443661994, 0, 15deg);
        transform: rotate3d(-0.5358172367, 0.8443661994, 0, 15deg);
    }
    92% {
        -webkit-transform: rotate3d(-0.4817413984, 0.876355296, 0, 15deg);
        transform: rotate3d(-0.4817413984, 0.876355296, 0, 15deg);
    }
    93% {
        -webkit-transform: rotate3d(-0.4257635689, 0.904888644, 0, 15deg);
        transform: rotate3d(-0.4257635689, 0.904888644, 0, 15deg);
    }
    94% {
        -webkit-transform: rotate3d(-0.3681044689, 0.9298543169, 0, 15deg);
        transform: rotate3d(-0.3681044689, 0.9298543169, 0, 15deg);
    }
    95% {
        -webkit-transform: rotate3d(-0.3089914068, 0.9511546228, 0, 15deg);
        transform: rotate3d(-0.3089914068, 0.9511546228, 0, 15deg);
    }
    96% {
        -webkit-transform: rotate3d(-0.2486573708, 0.9687065223, 0, 15deg);
        transform: rotate3d(-0.2486573708, 0.9687065223, 0, 15deg);
    }
    97% {
        -webkit-transform: rotate3d(-0.1873400968, 0.9824419959, 0, 15deg);
        transform: rotate3d(-0.1873400968, 0.9824419959, 0, 15deg);
    }
    98% {
        -webkit-transform: rotate3d(-0.1252811139, 0.9923083591, 0, 15deg);
        transform: rotate3d(-0.1252811139, 0.9923083591, 0, 15deg);
    }
    99% {
        -webkit-transform: rotate3d(-0.062724773, 0.9982685263, 0, 15deg);
        transform: rotate3d(-0.062724773, 0.9982685263, 0, 15deg);
    }
    100% {
        -webkit-transform: rotate3d(0.000082741, 1.000301224, 0, 15deg);
        transform: rotate3d(0.000082741, 1.000301224, 0, 15deg);
    }
}

@keyframes float {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 15deg);
        transform: rotate3d(0, 1, 0, 15deg);
    }
    1% {
        -webkit-transform: rotate3d(0.0627905195, 0.9980267284, 0, 15deg);
        transform: rotate3d(0.0627905195, 0.9980267284, 0, 15deg);
    }
    2% {
        -webkit-transform: rotate3d(0.1253332336, 0.9921147013, 0, 15deg);
        transform: rotate3d(0.1253332336, 0.9921147013, 0, 15deg);
    }
    3% {
        -webkit-transform: rotate3d(0.1873813146, 0.9822872507, 0, 15deg);
        transform: rotate3d(0.1873813146, 0.9822872507, 0, 15deg);
    }
    4% {
        -webkit-transform: rotate3d(0.2486898872, 0.9685831611, 0, 15deg);
        transform: rotate3d(0.2486898872, 0.9685831611, 0, 15deg);
    }
    5% {
        -webkit-transform: rotate3d(0.3090169944, 0.9510565163, 0, 15deg);
        transform: rotate3d(0.3090169944, 0.9510565163, 0, 15deg);
    }
    6% {
        -webkit-transform: rotate3d(0.3681245527, 0.9297764859, 0, 15deg);
        transform: rotate3d(0.3681245527, 0.9297764859, 0, 15deg);
    }
    7% {
        -webkit-transform: rotate3d(0.4257792916, 0.9048270525, 0, 15deg);
        transform: rotate3d(0.4257792916, 0.9048270525, 0, 15deg);
    }
    8% {
        -webkit-transform: rotate3d(0.4817536741, 0.87630668, 0, 15deg);
        transform: rotate3d(0.4817536741, 0.87630668, 0, 15deg);
    }
    9% {
        -webkit-transform: rotate3d(0.535826795, 0.8443279255, 0, 15deg);
        transform: rotate3d(0.535826795, 0.8443279255, 0, 15deg);
    }
    10% {
        -webkit-transform: rotate3d(0.5877852523, 0.8090169944, 0, 15deg);
        transform: rotate3d(0.5877852523, 0.8090169944, 0, 15deg);
    }
    11% {
        -webkit-transform: rotate3d(0.6374239897, 0.7705132428, 0, 15deg);
        transform: rotate3d(0.6374239897, 0.7705132428, 0, 15deg);
    }
    12% {
        -webkit-transform: rotate3d(0.6845471059, 0.7289686274, 0, 15deg);
        transform: rotate3d(0.6845471059, 0.7289686274, 0, 15deg);
    }
    13% {
        -webkit-transform: rotate3d(0.7289686274, 0.6845471059, 0, 15deg);
        transform: rotate3d(0.7289686274, 0.6845471059, 0, 15deg);
    }
    14% {
        -webkit-transform: rotate3d(0.7705132428, 0.6374239897, 0, 15deg);
        transform: rotate3d(0.7705132428, 0.6374239897, 0, 15deg);
    }
    15% {
        -webkit-transform: rotate3d(0.8090169944, 0.5877852523, 0, 15deg);
        transform: rotate3d(0.8090169944, 0.5877852523, 0, 15deg);
    }
    16% {
        -webkit-transform: rotate3d(0.8443279255, 0.535826795, 0, 15deg);
        transform: rotate3d(0.8443279255, 0.535826795, 0, 15deg);
    }
    17% {
        -webkit-transform: rotate3d(0.87630668, 0.4817536741, 0, 15deg);
        transform: rotate3d(0.87630668, 0.4817536741, 0, 15deg);
    }
    18% {
        -webkit-transform: rotate3d(0.9048270525, 0.4257792916, 0, 15deg);
        transform: rotate3d(0.9048270525, 0.4257792916, 0, 15deg);
    }
    19% {
        -webkit-transform: rotate3d(0.9297764859, 0.3681245527, 0, 15deg);
        transform: rotate3d(0.9297764859, 0.3681245527, 0, 15deg);
    }
    20% {
        -webkit-transform: rotate3d(0.9510565163, 0.3090169944, 0, 15deg);
        transform: rotate3d(0.9510565163, 0.3090169944, 0, 15deg);
    }
    21% {
        -webkit-transform: rotate3d(0.9685831611, 0.2486898872, 0, 15deg);
        transform: rotate3d(0.9685831611, 0.2486898872, 0, 15deg);
    }
    22% {
        -webkit-transform: rotate3d(0.9822872507, 0.1873813146, 0, 15deg);
        transform: rotate3d(0.9822872507, 0.1873813146, 0, 15deg);
    }
    23% {
        -webkit-transform: rotate3d(0.9921147013, 0.1253332336, 0, 15deg);
        transform: rotate3d(0.9921147013, 0.1253332336, 0, 15deg);
    }
    24% {
        -webkit-transform: rotate3d(0.9980267284, 0.0627905195, 0, 15deg);
        transform: rotate3d(0.9980267284, 0.0627905195, 0, 15deg);
    }
    25% {
        -webkit-transform: rotate3d(1, 0, 0, 15deg);
        transform: rotate3d(1, 0, 0, 15deg);
    }
    26% {
        -webkit-transform: rotate3d(0.9980267284, -0.0627905195, 0, 15deg);
        transform: rotate3d(0.9980267284, -0.0627905195, 0, 15deg);
    }
    27% {
        -webkit-transform: rotate3d(0.9921147013, -0.1253332336, 0, 15deg);
        transform: rotate3d(0.9921147013, -0.1253332336, 0, 15deg);
    }
    28% {
        -webkit-transform: rotate3d(0.9822872507, -0.1873813146, 0, 15deg);
        transform: rotate3d(0.9822872507, -0.1873813146, 0, 15deg);
    }
    29% {
        -webkit-transform: rotate3d(0.9685831611, -0.2486898872, 0, 15deg);
        transform: rotate3d(0.9685831611, -0.2486898872, 0, 15deg);
    }
    30% {
        -webkit-transform: rotate3d(0.9510565163, -0.3090169944, 0, 15deg);
        transform: rotate3d(0.9510565163, -0.3090169944, 0, 15deg);
    }
    31% {
        -webkit-transform: rotate3d(0.9297764859, -0.3681245527, 0, 15deg);
        transform: rotate3d(0.9297764859, -0.3681245527, 0, 15deg);
    }
    32% {
        -webkit-transform: rotate3d(0.9048270525, -0.4257792916, 0, 15deg);
        transform: rotate3d(0.9048270525, -0.4257792916, 0, 15deg);
    }
    33% {
        -webkit-transform: rotate3d(0.87630668, -0.4817536741, 0, 15deg);
        transform: rotate3d(0.87630668, -0.4817536741, 0, 15deg);
    }
    34% {
        -webkit-transform: rotate3d(0.8443279255, -0.535826795, 0, 15deg);
        transform: rotate3d(0.8443279255, -0.535826795, 0, 15deg);
    }
    35% {
        -webkit-transform: rotate3d(0.8090169944, -0.5877852523, 0, 15deg);
        transform: rotate3d(0.8090169944, -0.5877852523, 0, 15deg);
    }
    36% {
        -webkit-transform: rotate3d(0.7705132428, -0.6374239897, 0, 15deg);
        transform: rotate3d(0.7705132428, -0.6374239897, 0, 15deg);
    }
    37% {
        -webkit-transform: rotate3d(0.7289686274, -0.6845471059, 0, 15deg);
        transform: rotate3d(0.7289686274, -0.6845471059, 0, 15deg);
    }
    38% {
        -webkit-transform: rotate3d(0.6845471059, -0.7289686274, 0, 15deg);
        transform: rotate3d(0.6845471059, -0.7289686274, 0, 15deg);
    }
    39% {
        -webkit-transform: rotate3d(0.6374239897, -0.7705132428, 0, 15deg);
        transform: rotate3d(0.6374239897, -0.7705132428, 0, 15deg);
    }
    40% {
        -webkit-transform: rotate3d(0.5877852523, -0.8090169944, 0, 15deg);
        transform: rotate3d(0.5877852523, -0.8090169944, 0, 15deg);
    }
    41% {
        -webkit-transform: rotate3d(0.535826795, -0.8443279255, 0, 15deg);
        transform: rotate3d(0.535826795, -0.8443279255, 0, 15deg);
    }
    42% {
        -webkit-transform: rotate3d(0.4817536741, -0.87630668, 0, 15deg);
        transform: rotate3d(0.4817536741, -0.87630668, 0, 15deg);
    }
    43% {
        -webkit-transform: rotate3d(0.4257792916, -0.9048270525, 0, 15deg);
        transform: rotate3d(0.4257792916, -0.9048270525, 0, 15deg);
    }
    44% {
        -webkit-transform: rotate3d(0.3681245527, -0.9297764859, 0, 15deg);
        transform: rotate3d(0.3681245527, -0.9297764859, 0, 15deg);
    }
    45% {
        -webkit-transform: rotate3d(0.3090169944, -0.9510565163, 0, 15deg);
        transform: rotate3d(0.3090169944, -0.9510565163, 0, 15deg);
    }
    46% {
        -webkit-transform: rotate3d(0.2486898872, -0.9685831611, 0, 15deg);
        transform: rotate3d(0.2486898872, -0.9685831611, 0, 15deg);
    }
    47% {
        -webkit-transform: rotate3d(0.1873813146, -0.9822872507, 0, 15deg);
        transform: rotate3d(0.1873813146, -0.9822872507, 0, 15deg);
    }
    48% {
        -webkit-transform: rotate3d(0.1253332336, -0.9921147013, 0, 15deg);
        transform: rotate3d(0.1253332336, -0.9921147013, 0, 15deg);
    }
    49% {
        -webkit-transform: rotate3d(0.0627905195, -0.9980267284, 0, 15deg);
        transform: rotate3d(0.0627905195, -0.9980267284, 0, 15deg);
    }
    50% {
        -webkit-transform: rotate3d(0, -0.9999999999, 0, 15deg);
        transform: rotate3d(0, -0.9999999999, 0, 15deg);
    }
    51% {
        -webkit-transform: rotate3d(-0.0627905195, -0.9980267283, 0, 15deg);
        transform: rotate3d(-0.0627905195, -0.9980267283, 0, 15deg);
    }
    52% {
        -webkit-transform: rotate3d(-0.1253332335, -0.9921147011, 0, 15deg);
        transform: rotate3d(-0.1253332335, -0.9921147011, 0, 15deg);
    }
    53% {
        -webkit-transform: rotate3d(-0.1873813145, -0.9822872505, 0, 15deg);
        transform: rotate3d(-0.1873813145, -0.9822872505, 0, 15deg);
    }
    54% {
        -webkit-transform: rotate3d(-0.2486898871, -0.9685831607, 0, 15deg);
        transform: rotate3d(-0.2486898871, -0.9685831607, 0, 15deg);
    }
    55% {
        -webkit-transform: rotate3d(-0.3090169943, -0.9510565157, 0, 15deg);
        transform: rotate3d(-0.3090169943, -0.9510565157, 0, 15deg);
    }
    56% {
        -webkit-transform: rotate3d(-0.3681245525, -0.929776485, 0, 15deg);
        transform: rotate3d(-0.3681245525, -0.929776485, 0, 15deg);
    }
    57% {
        -webkit-transform: rotate3d(-0.4257792914, -0.9048270511, 0, 15deg);
        transform: rotate3d(-0.4257792914, -0.9048270511, 0, 15deg);
    }
    58% {
        -webkit-transform: rotate3d(-0.4817536738, -0.8763066781, 0, 15deg);
        transform: rotate3d(-0.4817536738, -0.8763066781, 0, 15deg);
    }
    59% {
        -webkit-transform: rotate3d(-0.5358267945, -0.8443279226, 0, 15deg);
        transform: rotate3d(-0.5358267945, -0.8443279226, 0, 15deg);
    }
    60% {
        -webkit-transform: rotate3d(-0.5877852516, -0.8090169902, 0, 15deg);
        transform: rotate3d(-0.5877852516, -0.8090169902, 0, 15deg);
    }
    61% {
        -webkit-transform: rotate3d(-0.6374239888, -0.7705132368, 0, 15deg);
        transform: rotate3d(-0.6374239888, -0.7705132368, 0, 15deg);
    }
    62% {
        -webkit-transform: rotate3d(-0.6845471045, -0.7289686189, 0, 15deg);
        transform: rotate3d(-0.6845471045, -0.7289686189, 0, 15deg);
    }
    63% {
        -webkit-transform: rotate3d(-0.7289686253, -0.6845470938, 0, 15deg);
        transform: rotate3d(-0.7289686253, -0.6845470938, 0, 15deg);
    }
    64% {
        -webkit-transform: rotate3d(-0.7705132398, -0.6374239727, 0, 15deg);
        transform: rotate3d(-0.7705132398, -0.6374239727, 0, 15deg);
    }
    65% {
        -webkit-transform: rotate3d(-0.8090169901, -0.5877852283, 0, 15deg);
        transform: rotate3d(-0.8090169901, -0.5877852283, 0, 15deg);
    }
    66% {
        -webkit-transform: rotate3d(-0.8443279194, -0.5358267614, 0, 15deg);
        transform: rotate3d(-0.8443279194, -0.5358267614, 0, 15deg);
    }
    67% {
        -webkit-transform: rotate3d(-0.8763066715, -0.4817536274, 0, 15deg);
        transform: rotate3d(-0.8763066715, -0.4817536274, 0, 15deg);
    }
    68% {
        -webkit-transform: rotate3d(-0.9048270404, -0.425779227, 0, 15deg);
        transform: rotate3d(-0.9048270404, -0.425779227, 0, 15deg);
    }
    69% {
        -webkit-transform: rotate3d(-0.9297764691, -0.3681244637, 0, 15deg);
        transform: rotate3d(-0.9297764691, -0.3681244637, 0, 15deg);
    }
    70% {
        -webkit-transform: rotate3d(-0.9510564929, -0.3090168724, 0, 15deg);
        transform: rotate3d(-0.9510564929, -0.3090168724, 0, 15deg);
    }
    71% {
        -webkit-transform: rotate3d(-0.9685831287, -0.2486897207, 0, 15deg);
        transform: rotate3d(-0.9685831287, -0.2486897207, 0, 15deg);
    }
    72% {
        -webkit-transform: rotate3d(-0.9822872061, -0.1873810883, 0, 15deg);
        transform: rotate3d(-0.9822872061, -0.1873810883, 0, 15deg);
    }
    73% {
        -webkit-transform: rotate3d(-0.9921146401, -0.1253329274, 0, 15deg);
        transform: rotate3d(-0.9921146401, -0.1253329274, 0, 15deg);
    }
    74% {
        -webkit-transform: rotate3d(-0.9980266448, -0.0627901069, 0, 15deg);
        transform: rotate3d(-0.9980266448, -0.0627901069, 0, 15deg);
    }
    75% {
        -webkit-transform: rotate3d(-0.9999998862, 0.0000005537, 0, 15deg);
        transform: rotate3d(-0.9999998862, 0.0000005537, 0, 15deg);
    }
    76% {
        -webkit-transform: rotate3d(-0.9980265742, 0.0627912598, 0, 15deg);
        transform: rotate3d(-0.9980265742, 0.0627912598, 0, 15deg);
    }
    77% {
        -webkit-transform: rotate3d(-0.9921144932, 0.1253342195, 0, 15deg);
        transform: rotate3d(-0.9921144932, 0.1253342195, 0, 15deg);
    }
    78% {
        -webkit-transform: rotate3d(-0.982286971, 0.1873826227, 0, 15deg);
        transform: rotate3d(-0.982286971, 0.1873826227, 0, 15deg);
    }
    79% {
        -webkit-transform: rotate3d(-0.9685827866, 0.2486916166, 0, 15deg);
        transform: rotate3d(-0.9685827866, 0.2486916166, 0, 15deg);
    }
    80% {
        -webkit-transform: rotate3d(-0.9510560166, 0.3090192726, 0, 15deg);
        transform: rotate3d(-0.9510560166, 0.3090192726, 0, 15deg);
    }
    81% {
        -webkit-transform: rotate3d(-0.9297758216, 0.3681275437, 0, 15deg);
        transform: rotate3d(-0.9297758216, 0.3681275437, 0, 15deg);
    }
    82% {
        -webkit-transform: rotate3d(-0.9048261725, 0.425783205, 0, 15deg);
        transform: rotate3d(-0.9048261725, 0.425783205, 0, 15deg);
    }
    83% {
        -webkit-transform: rotate3d(-0.8763055184, 0.4817587777, 0, 15deg);
        transform: rotate3d(-0.8763055184, 0.4817587777, 0, 15deg);
    }
    84% {
        -webkit-transform: rotate3d(-0.8443263971, 0.5358334293, 0, 15deg);
        transform: rotate3d(-0.8443263971, 0.5358334293, 0, 15deg);
    }
    85% {
        -webkit-transform: rotate3d(-0.80901499, 0.5877938496, 0, 15deg);
        transform: rotate3d(-0.80901499, 0.5877938496, 0, 15deg);
    }
    86% {
        -webkit-transform: rotate3d(-0.7705106226, 0.6374350969, 0, 15deg);
        transform: rotate3d(-0.7705106226, 0.6374350969, 0, 15deg);
    }
    87% {
        -webkit-transform: rotate3d(-0.7289652129, 0.6845614127, 0, 15deg);
        transform: rotate3d(-0.7289652129, 0.6845614127, 0, 15deg);
    }
    88% {
        -webkit-transform: rotate3d(-0.6845426698, 0.7289870018, 0, 15deg);
        transform: rotate3d(-0.6845426698, 0.7289870018, 0, 15deg);
    }
    89% {
        -webkit-transform: rotate3d(-0.6374182434, 0.7705367741, 0, 15deg);
        transform: rotate3d(-0.6374182434, 0.7705367741, 0, 15deg);
    }
    90% {
        -webkit-transform: rotate3d(-0.5877778306, 0.8090470459, 0, 15deg);
        transform: rotate3d(-0.5877778306, 0.8090470459, 0, 15deg);
    }
    91% {
        -webkit-transform: rotate3d(-0.5358172367, 0.8443661994, 0, 15deg);
        transform: rotate3d(-0.5358172367, 0.8443661994, 0, 15deg);
    }
    92% {
        -webkit-transform: rotate3d(-0.4817413984, 0.876355296, 0, 15deg);
        transform: rotate3d(-0.4817413984, 0.876355296, 0, 15deg);
    }
    93% {
        -webkit-transform: rotate3d(-0.4257635689, 0.904888644, 0, 15deg);
        transform: rotate3d(-0.4257635689, 0.904888644, 0, 15deg);
    }
    94% {
        -webkit-transform: rotate3d(-0.3681044689, 0.9298543169, 0, 15deg);
        transform: rotate3d(-0.3681044689, 0.9298543169, 0, 15deg);
    }
    95% {
        -webkit-transform: rotate3d(-0.3089914068, 0.9511546228, 0, 15deg);
        transform: rotate3d(-0.3089914068, 0.9511546228, 0, 15deg);
    }
    96% {
        -webkit-transform: rotate3d(-0.2486573708, 0.9687065223, 0, 15deg);
        transform: rotate3d(-0.2486573708, 0.9687065223, 0, 15deg);
    }
    97% {
        -webkit-transform: rotate3d(-0.1873400968, 0.9824419959, 0, 15deg);
        transform: rotate3d(-0.1873400968, 0.9824419959, 0, 15deg);
    }
    98% {
        -webkit-transform: rotate3d(-0.1252811139, 0.9923083591, 0, 15deg);
        transform: rotate3d(-0.1252811139, 0.9923083591, 0, 15deg);
    }
    99% {
        -webkit-transform: rotate3d(-0.062724773, 0.9982685263, 0, 15deg);
        transform: rotate3d(-0.062724773, 0.9982685263, 0, 15deg);
    }
    100% {
        -webkit-transform: rotate3d(0.000082741, 1.000301224, 0, 15deg);
        transform: rotate3d(0.000082741, 1.000301224, 0, 15deg);
    }
}
